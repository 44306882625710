var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    attrs: {
      "header-class": "py-50 text-airline px-1",
      "body-class": "pb-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          class: "fw-700 text-nowrap ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-2')
        }, [_vm._v(" " + _vm._s(_vm.$t("flight.agencyInfo")) + " ")]), _c('BButton', {
          staticClass: "p-50 text-nowrap",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('toggleCollapse');
            }
          }
        }, [!_vm.isCollapsed ? _c('feather-icon', {
          attrs: {
            "icon": "ChevronDownIcon",
            "size": "17"
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "ChevronUpIcon",
            "size": "17"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('BCollapse', {
    attrs: {
      "visible": !_vm.isCollapsed
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.isEmpty(_vm.agencyData)
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Phone",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "phone-agency"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.$t('flight.phoneNumber')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "phone-agency",
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "lazy-formatter": "",
            "type": "number",
            "disabled": true,
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('flight.placeholderInput')
          },
          model: {
            value: _vm.agencyData.phoneNumber,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyData, "phoneNumber", $$v);
            },
            expression: "agencyData.phoneNumber"
          }
        }), validationContext.errors[0] ? _c('b-tooltip', {
          attrs: {
            "target": "phone-agency",
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "viewport",
            "variant": "danger",
            "title": validationContext.errors[0]
          }
        }, [_c('span', {
          staticClass: "text-white py-25 mb-0"
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-for": "email-agency"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.$t('flight.email')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "email-agency",
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "lazy-formatter": "",
            "disabled": true,
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('flight.placeholderInput')
          },
          model: {
            value: _vm.agencyData.emailAddress,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyData, "emailAddress", $$v);
            },
            expression: "agencyData.emailAddress"
          }
        }), validationContext.errors[0] ? _c('b-tooltip', {
          attrs: {
            "target": "email-agency",
            "triggers": "hover focus",
            "placement": "top",
            "boundary": "viewport",
            "variant": "danger",
            "title": validationContext.errors[0]
          }
        }, [_c('span', {
          staticClass: "text-white py-25 mb-0"
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }